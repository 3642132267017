import * as qs from 'query-string';
import decodeJwt from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';
import * as Config from './Config'

// A function decorating a dataProvider for handling user profiles
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    return fetchUtils.fetchJson(url, options);
}

/*
const  updateFeatureFlags = async(tapToken, tokenExpires) => {
  let requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + tapToken},
  }
  await fetch(process.env.REACT_APP_API_URL + '/feature', requestOptions)
  .then(response => {
    const featureFlags = { expires: new Date(tokenExpires).toISOString(), flags: []};                
    if (response.ok && featureFlags['flags'].length === 0) {
      response.json().then(function(result) {
        if (result !== undefined) {
          result.forEach(element => {
            if (element.featureEnabled) {
              featureFlags['flags'].push(element.controlledFeature);
            }
          });
        }
        //localStorage.setItem('feature-flags', featureFlags['flags'].map(String).join(','));
        //localStorage.setItem('feature-flags-expires', featureFlags['expires'])
      });
    }
  })
  .then(response => {
  });
}
*/

var errorShown = false;

const Auth = {
    // called when the user attempts to log in
    login: () => {
        //console.log("Inside AUTH_LOGIN");
        return fetch(process.env.REACT_APP_MY_ID_URL, { mode: 'no-cors'})
        .then(response => {
            //console.log("response ", response);
            //console.log("response.status ", response.status);
            return response.json();
        })
        .then(response => {
          //console.log("HTTP 301 response ", response);
        })
        .then((body) => {
        });
    },
    logout: () => {
      //console.log("Inside logout ")
      const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient)
      return dataProvider.getMany('users/logout', {
          id: 0
        }).then((test) => {
            // localStorage.removeItem('username');
            // localStorage.removeItem('userid');
            // localStorage.removeItem('imageUrl');
            // localStorage.removeItem('tap-token');
            // localStorage.removeItem('permissions');
            // localStorage.removeItem('attemptedURL');
            localStorage.clear()
        }).finally(() => {
          window.history.pushState({}, document.title, "/#/");
          window.location = "/#"
          //window.location.reload()
        })
    },
    checkError: error => {
        const { status } = error;
        if (status === 401 || status === 403) {
            // localStorage.removeItem('username');
            // localStorage.removeItem('userid');
            // localStorage.removeItem('imageUrl');
            // localStorage.removeItem('tap-token');
            // localStorage.removeItem('permissions');
            // localStorage.removeItem('attemptedURL');
            localStorage.clear();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {     
      const parsed = qs.parse(window.location.search);
      var token = parsed.token;
      var error = parsed.error;      
      var tapToken = localStorage.getItem('tap-token');
      if (tapToken) {        
        const decodedTapToken = decodeJwt(tapToken);
        const decodedTapTokenExpiration = new Date(decodedTapToken.exp * 1000)        
        const currentDate = new Date()
        const minsTillTokenExpires = Math.floor(((decodedTapTokenExpiration.getTime() - currentDate.getTime())/1000/60) << 0)                
        if (decodedTapTokenExpiration < currentDate) {
          console.log("token expired ")
        } else {
          console.log("token not expired, expires in " + minsTillTokenExpires + " mins ")
          if (minsTillTokenExpires < 176) {
            console.log("Token expires in " + minsTillTokenExpires + " let's regenerate token ")
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', Authorization: tapToken},
            };
            fetch(process.env.REACT_APP_API_URL + '/users/extendToken', requestOptions)
            .then(response => {                
                return response.text()
            })            
            .then((token) => {
              token = token.replace("\"", "")              
              const decodedToken = decodeJwt(token);              
              localStorage.setItem('permissions', decodedToken.permissions);
              localStorage.setItem('email', decodedToken.email);
              localStorage.setItem('firstname', decodedToken.firstname);
              localStorage.setItem('lastname', decodedToken.lastname);                      
              localStorage.setItem('tap-token', 'Bearer ' + token);
              console.log("new token expires " + new Date(decodedToken.exp * 1000))
              //updateFeatureFlags(token, decodedToken.exp * 1000);
            });
          }
        }
      }
      
      if (token) {
        const decodedToken = decodeJwt(token);
        localStorage.setItem('permissions', decodedToken.permissions);
        localStorage.setItem('email', decodedToken.email);
        localStorage.setItem('firstname', decodedToken.firstname);
        localStorage.setItem('lastname', decodedToken.lastname);        
        console.log("token expires " + new Date(decodedToken.exp * 1000))
        if (!localStorage.getItem('tap-token')) {
          localStorage.setItem('tap-token', 'Bearer ' + token);
          window.history.pushState({}, document.title, "/#/");
          window.location = localStorage.getItem('attemptedURL') ? localStorage.getItem('attemptedURL') : "/#/"
          window.location.reload();
        }
      }
      if (error && !errorShown) {
        errorShown = true;
        console.log("error ", error);
        toast.error(error, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: false,
            hideProgressBar: true,
            autoClose: false
        });
        return Promise.reject();
      } else {
        if (!localStorage.getItem('tap-token') && window.location.hash !== "#/" && window.location.hash !== "#" && window.location.hash !== "/#" && window.location.hash !== "/#/login")  {
          console.log("Rejecting promise")
          if (window.location.hash !== '#/login')
            localStorage.setItem('attemptedURL', window.location.hash);
          window.history.pushState({}, document.title, "/#/");
          window.location = "/#/login"
          window.location.reload();
        }
        if (localStorage.getItem('tap-token') && localStorage.getItem('permissions') && window.location.hash !== '#/login' && localStorage.getItem('attemptedURL')) {
          window.location = localStorage.getItem('attemptedURL')
          localStorage.removeItem('attemptedURL');
          window.location.reload();
        }
        return localStorage.getItem('tap-token') && localStorage.getItem('permissions') ? Promise.resolve() : Promise.reject();
      }
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    isFlagEnabled: (flag) => {
      //let isEnabled;
      //if (localStorage.getItem('feature-flags')) {
        //isEnabled = localStorage.getItem('feature-flags').split(',').includes(flag);
      //}      
      return Config.ENABLED_FEATURE_FLAGS && Config.ENABLED_FEATURE_FLAGS.split(',').includes(flag);
    }
};

export default Auth
